// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

import TYPES from '@/types';

export default class OperationsViewModel {
  i18n_namespace = 'components.allianz-dashboard.operations';

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  operations = [
    {
      title: `${this.translate('requests')} \n ${this.translate('pending')}`,
      icon: 'mdi-clock',
      modal: 'show_requests_dialog',
    },
    {
      title: `${this.translate('contributions')} \n ${this.translate('recurring')}`,
      icon: 'mdi-refresh-circle',
      modal: 'show_recurring_contribution_dialog',
    },
    {
      title: this.translate('account_statement'),
      icon: 'mdi-text-box',
      modal: 'show_account_statement_dialog',
    },
    {
      title: this.translate('bank_account'),
      icon: 'mdi-credit-card',
      modal: 'show_bank_account_dialog',
    },
    {
      title: this.translate('beneficiaries'),
      icon: 'mdi-account-supervisor',
      modal: 'show_beneficiaries_dialog',
    },
    {
      title: `${this.translate('details_from')} \n ${this.translate('policy')}`,
      icon: 'mdi-file-document',
      modal: 'show_policy_details_dialog',
    },
  ];

  modals: Record<string, boolean> = {
    show_requests_dialog: false,
    show_recurring_contribution_dialog: false,
    show_account_statement_dialog: false,
    show_bank_account_dialog: false,
    show_beneficiaries_dialog: false,
    show_policy_details_dialog: false,
  }

  showModal(modal: string) {
    this.modals[modal] = true;
  }
}
