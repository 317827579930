













































import { Component, Vue } from 'vue-property-decorator';
import ExtrasViewModel from '@/vue-app/view-models/allianz-dashboard/extras-view-model';

@Component({
  name: 'Extras',
  components: {
    RequestsDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/RequestsDialog.vue'),
    RecurringDepositDialog: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositDialog.vue'),
    BeneficiariesDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/BeneficiariesDialog.vue'),
    PolicyDetails: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/PolicyDetailsDialog.vue'),
    BankAccountDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/BankAccountDialog.vue'),
    AccountStatementsDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/AccountStatementsDialog.vue'),
    ExtraOption: () => import('@/vue-app/components/allianz-dashboard/ExtraOption.vue'),
  },
})
export default class Extras extends Vue {
  extras_view_model = Vue.observable(new ExtrasViewModel());

  endProcess() {
    this.extras_view_model.modals.show_recurring_contribution_dialog = false;
  }
}
